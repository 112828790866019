import React from 'react';
import Button from '../components/common/Button';
import ContentContainer from '../components/common/ContentContainer';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import { useStaticQuery, graphql, Link } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import {
  faTwitter,
  faFacebook,
  faInstagram,
  faYoutube
} from '@fortawesome/free-brands-svg-icons';

type GetStartedItem = {
  title: string;
  body: string;
  cta: string;
  link?: string;
  externalLink?: boolean;
};

const getStartedContent: GetStartedItem[] = [
  {
    title: 'Explore videos of classroom instruction',
    body:
      'Watch clips of teachers as they give minilessons, conferences, and small groups, Study kids as they talk in their book clubs, give each other feedback about their writing, work with partners on their reading. or engage in flash debates. Watch the videos of workshop teaching and learning.',
    cta: 'Watch Classroom Videos',
    link: 'resources'
  },
  {
    title: 'Attend an Event',
    body:
      'We offer: workshops and institutes on a variety of topics; a weekly virtual ‘Supper Club’ during which we interview one of the nation’s leading educators; and free ‘Saturday Reunions’ with hundreds of workshops for teachers',
    cta: 'View Events',
    link: 'events'
  },
  {
    title:
      'Learn more about Units of Study curriculum for reading, writing, and phonics',
    body:
      'You may choose to watch the orientation videos for reading and writing workshop for the grade level(s) you teach. You may also choose to download samples of units of study curriculum from our publisher, Heinemann',
    cta: 'Explore Units of Study',
    link: 'https://www.unitsofstudy.com/',
    externalLink: true
  },
  {
    title: 'Discover our free resources',
    body:
      'We’ve compiled a range of resources for workshop teaching. From lists of recommended books to digital performance assessments, you can explore our resources according to the grade(s) you teach or the subjects that are of particular interest to you.',
    cta: 'Explore our Resources',
    link: 'resource-center'
  },
  {
    title:
      'Connect your school or district with us for onsite or virtual professional development',
    body:
      'Our staff developers work alongside teachers and school leaders, to provide demonstration teaching and coaching aimed at supporting the implementation of reading, writing, or phonics workshops, as well as critical literacies.',
    cta: 'View Services',
    link: 'services'
  },
  {
    title: 'Join our Community of Parents, Caregivers, and Families',
    body:
      "Support your child's learning at home by watching educational videos, exploring book lists, and attending family conference days.",
    cta: 'View Free Family Supports',
    link: 'resources/supports-for-familes'
  }
];

interface GetStartedBlockProps {
  item: GetStartedItem;
  itemNum: number;
  children?: React.ReactNode;
}

const GetStartedBlock: React.FC<GetStartedBlockProps> = props => {
  return (
    <div className="flex p-10 bg-white rounded-lg shadow border-l-8 border-blue-400">
      <div className="w-1/5">
        <p className="text-blue-400 text-6xl sm:text-9xl mr-10 font-semibold text-center">
          {props.itemNum}
        </p>
      </div>
      <div className="w-4/5">
        <h2 className="text-primary text-2xl mb-6 font-semibold">
          {props.item.title}
        </h2>
        <p className="text-gray-700 leading-relaxed">{props.item.body}</p>
        <div className="mt-8">
          {props.children ? (
            <>{props.children}</>
          ) : (
            <>
              {props.item.externalLink ? (
                <a href={props.item.link} target="_blank">
                  <Button text={props.item.cta} />
                </a>
              ) : (
                <Link to={`/${props.item.link}`}>
                  <Button text={props.item.cta} />
                </Link>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const GetStarted: React.FC = props => {
  const data = useStaticQuery(graphql`
    {
      bgImage: file(relativePath: { eq: "images/teachers-college-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title="Get Started" />
      <BackgroundImage fluid={data.bgImage.childImageSharp.fluid}>
        <div className="white-to-transparent">
          <div className="text-center flex flex-col w-3/4 sm:w-1/2 m-auto mb-10 py-10 sm:py-40">
            <h1 className="font-bold text-primary text-3xl">
              7 Ways to Get Started with Advancing Literacy at Teachers College
            </h1>
            <div className="text-center mt-8">
              <p className="text-gray-800 leading-relaxed text-xl">
                Maybe you’ve heard of reading or writing workshop, or critical
                literacies, but haven’t known how to begin this work. Welcome,
                we hope you’ll join our community through one or more of the
                paths described below.
              </p>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <ContentContainer>
        <section className="w-full xl:w-2/3 m-auto">
          <div className="mb-10">
            <GetStartedBlock
              item={{
                title:
                  'Join us on social media — Facebook, Twitter, Instagram and YouTube',
                body:
                  'Our Facebook groups are comprised of a robust community of teachers who share their experiences and tips for reading and writing workshop, content adaptations, and Units of Study implementation. We share tips and lessons across all platforms, and we also share videos on YouTube that support teachers and families. Join us on social media!',
                cta: 'Follow TCRWP on social media'
              }}
              itemNum={1}
            >
              <div className="grid grid-cols-1 lg:grid-cols-4 gap-2">
                <a
                  href="https://www.facebook.com/TCAdvancingLit"
                  target="_blank"
                >
                  <Button text="Facebook" icon={faFacebook} />
                </a>
                <a href="https://twitter.com/TCAdvancingLit" target="_blank">
                  <Button text="Twitter/X" icon={faTwitter} />
                </a>
                <a
                  href="https://www.instagram.com/tcadvancinglit"
                  target="_blank"
                >
                  <Button text="Instagram" icon={faInstagram} />
                </a>
                <a href="https://youtube.com/c/tcrwp/" target="_blank">
                  <Button text="YouTube" icon={faYoutube} />
                </a>
              </div>
            </GetStartedBlock>
          </div>
          {getStartedContent.map((item: GetStartedItem, i: number) => {
            return (
              <div className="mb-10" key={i}>
                <GetStartedBlock item={item} itemNum={i + 2} />
              </div>
            );
          })}
        </section>
      </ContentContainer>
    </Layout>
  );
};

export default GetStarted;
